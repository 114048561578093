import React from "react";
import { Helmet } from "react-helmet";
import TypeRow from 'components/DS/TypeRow';
import { Picture } from 'react-responsive-picture';
import TwoColMenuLayout from 'components/Layouts/TwoColMenuLayout';


const Typography = ({ match }) => {
  return (
    <TwoColMenuLayout>

        <Helmet>
            <title>Typography - CRM Design System | The University of Arizona, Tucson, Arizona</title>
            <meta name="description" content="Effective typography bring clarity and balance to textual information, speaking as the tone of the University of Arizona brand. Controlling the relationship between type size and spacing helps organize content into a clear hierarchy and structure." />
        </Helmet>

        <h1 class="mt-md-0">Typography</h1>
        <p class="lead text-muted">Effective typography bring clarity and balance to textual information, speaking as the tone of the University of Arizona brand. Controlling the relationship between type size and spacing helps organize content into a clear hierarchy and structure.</p>

        <section class="my-7">
            <h2>Fonts</h2>
            <p><strong>Proxima Nova</strong> is the standard sans serif font suite used by the University of Arizona for brand marketing, websites and interfaces. The fallback web font is Calibri, with additional ones following Arizona Bootstrap’s font stack. More on these options can be found on <a href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/content/font/" target="_blank" rel="noopener noreferrer">WebFont Proxima Nova - Arizona Bootstrap.</a></p>
        </section>

        <section class="my-7">
            <h2>Hierarchy</h2>
            <p>Weight, size, color, and font make up text styles. These text styles create the hierarchy of information within the application. Each text style is a category of text with an intended use and meaning. <strong>The base font size for 1 rem is 16pt.</strong></p>

            <table class="table my-4">
                <thead>
                    <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Selector</th>
                    <th scope="col">Size</th>
                    <th scope="col">Usage</th>
                    </tr>
                </thead>
                <tbody>
                    <TypeRow
                        col1={<h1 class="my-0">Page Title</h1>}
                        col2='H1'
                        col3='40px / 3rem'
                        col4='Primary Heading of a page' />

                    <TypeRow
                        col1={<h2 class="my-0">Section Heading</h2>}
                        col2='H2'
                        col3='32px / 2.375rem'
                        col4='Primary Heading of a page' />

                    <TypeRow
                        col1={<h3 class="my-0">Subsection Heading 1</h3>}
                        col2='H3'
                        col3='28px / 2.063rem'
                        col4='Section content header under Page Title' />

                    <TypeRow
                        col1={<h4 class="my-0">Subsection Heading 2</h4>}
                        col2='H4'
                        col3='24px / 1.75rem'
                        col4='Section content header under Page Title' />

                    <TypeRow
                        col1={<h5>Subsection Heading 3</h5>}
                        col2='H5'
                        col3='20px / 1.5rem'
                        col4='Sentence' />

                    <TypeRow
                        col1={<h6>Subsection Heading 4</h6>}
                        col2='H6'
                        col3='16px / 1.188rem'
                        col4='Section headings under Title 2 or sidebars' />

                    <TypeRow
                        col1={<p class="lead">Paragraph (Lead)</p>}
                        col2='p'
                        col3='16px / 1.875rem'
                        col4='Introductory paragraph, segue into main body content' />

                    <TypeRow
                        col1={<p>Paragraph</p>}
                        col2='p'
                        col3='16px / 1 rem'
                        col4='Main body text' />

                    <TypeRow
                        col1={<small>Small</small>}
                        col2='small'
                        col3='12.8pt / 1.188rem'
                        col4='Small text, captions, notes' />

                </tbody>
            </table>
        </section>


        <section class="my-7">
            <h2>Margin / Spacing</h2>
            <img class="img-fluid" src={process.env.PUBLIC_URL + '/images/typography_margins.svg'} alt="Margins Typography Example" />
        </section>


        <section class="my-7">
            <h2>Text Wrapping</h2>
            <p><strong>Aim to strike the right balance of line length for readability and comfort</strong> - do not let lines become too long or short as they may strain or confuse the eye. Generally the guideline is around 45-75 characters on desktop and 30-40 on mobile for body content. However, the optimal size for your application will be influenced by typeface, line spacing, letter width, and even the effective use of white space surrounding the text.</p>
            <Picture class="img-fluid" srcSet={process.env.PUBLIC_URL + '/images/text_wrap_examples@2x.png 2x,' + process.env.PUBLIC_URL + '/images/text_wrap_examples.png 1x'} />
        </section>

        <section class="my-7">
            <h2>Guidelines</h2>
            <ul>
                <li><strong>Use semantic HTML markup to communicate typography correctly.</strong> Headers should be defined with a &lt;h1 - h6&gt; tag, paragraphs with a &lt;p&gt; tag, &lt;ul&gt; / &lt;li&gt; for unordered lists, etc.</li>
                <li><strong>Do not leave any type open or undefined by an HTML tag.</strong> Not only will the stylesheet be unable to interpret the styles correctly, but browsers, search engines, screenreaders and other technology may depend on these tags to define content structure.</li>
                <li><strong>Avoid using an incorrect tag to alter the presentation or display of the type.</strong> (Example: using &lt;h3&gt; instead of &lt;h1&gt; on the main page title to shrink the text size.) It is recommended to use specific <a href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/content/typography/#section-heading" target="_blank" rel="noreferrer noopener">Heading style CSS classes</a> to override the default tag styling, or create your own CSS overrides if none are available.</li>
            </ul>
        </section>


        <section class="my-7">
            <h2>Additional Information</h2>
            <p><a target="_blank" rel="noopener noreferrer" href="https://brand.arizona.edu/applying-the-brand/typography">Typography | University of Arizona Brand Resources</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/content/typography/">Typography | Arizona Bootstrap</a></p>
        </section>

    </TwoColMenuLayout>
  );


};
export default Typography;