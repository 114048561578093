import React from "react";
import { Link } from "react-router-dom";

const Menu = ({ match }) => {
  return (

    <nav class="navbar-offcanvas offcanvas-toggle" id="navbarOffcanvasDemo">

        { /* Offcanvas header (mobile) */ }
        <div class="navbar-offcanvas-header">
            <div class="bg-chili d-flex justify-content-between align-items-center bg-">
                <a href="/" class="btn btn-redbar">
                <span class="icon material-icons-sharp"> home </span>
                <span class="icon-text"> home </span>
                </a>
                <button data-toggle="offcanvas" type="button" data-target="#navbarOffcanvasDemo" aria-controls="navbarOffcanvasDemo" class="btn btn-redbar" aria-expanded="true">
                <span class="icon material-icons-sharp"> close </span>
                <span class="icon-text"> close </span>
                </button>
            </div>

            <section class="region region-navigation-offcanvas">
                <div class="search-block-form bg-white navbar-offcanvas-search" data-drupal-selector="search-block-form-2" id="block-az-barrio-offcanvas-searchform" role="search">
                    {/* <form action="/search/node" method="get" id="search-block-form--2" accept-charset="UTF-8" class="search-form search-block-form">
                        <div class="input-group">
                        <label for="edit-keys--2" class="sr-only">Search</label>
                        <input title="Enter the terms you wish to search for." data-drupal-selector="edit-keys" type="search" id="edit-keys--2" name="keys" value="" size="15" maxlength="128" class="form-search form-control" placeholder="Search this site" aria-label="Search this site" />
                        <div data-drupal-selector="edit-actions" class="form-actions js-form-wrapper input-group-append" id="edit-actions--2">
                            <button data-drupal-selector="edit-submit" type="submit" id="edit-submit--2" value="Search" class="button js-form-submit form-submit btn">
                            <span class="material-icons-sharp">search</span>
                            </button>
                        </div>
                        </div>
                    </form> */}
                </div>
            </section>
        </div>


        <section class="region region-navigation">
            <nav role="navigation">
                <h2 class="sr-only" >Main navigation</h2>
                <ul class="clearfix navbar-nav flex-lg-row">
                    <li class="nav-item">
                        <Link className="text-uppercase nav-link" to="/">Home</Link>
                    </li>

                    <li className="nav-item">
                        <Link className="text-uppercase nav-link" to="/vision">Vision</Link>
                    </li>

                    <li className="nav-item">
                        <Link className="text-uppercase nav-link" to="/getstarted">Get Started</Link>
                    </li>

                    <li className="nav-item dropdown">
                        <Link className="text-uppercase nav-link" to="/documentation">Documentation</Link>
                    </li>

                    <li className="nav-item">
                        <a className="text-uppercase nav-link" href={process.env.PUBLIC_URL + '/ui_library/index.html'} target="_blank" rel="noreferrer noopener">UI Library</a>
                    </li>
                </ul>
            </nav>
        </section>
    </nav>
  );
};
export default Menu;