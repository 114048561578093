import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Home = ({ match }) => {
  return (
    <>
      <Helmet>
        <title>CRM Design System | The University of Arizona, Tucson, Arizona</title>
        <meta name="description" content="The CRM design system is a foundation to ensure that Trellis solutions provides a cohesive, accessible, and dynamic user experience that aligns with the University of Arizona language and tone." />
      </Helmet>

      <div class="entity entity-paragraphs-item paragraphs-item-uaqs-full-width-bg-wrapper bgHome_l1 background-wrapper bg-blue py-0">
        <div class="home_BG"></div>
        <div class="home_BG_2"></div>
        <div class="inner bgHome_l2 background-wrapper">
          <div class="container my-md-4">
            <div class="row">
              <div class="col-lg-6">
                <h1 class="text-white mt-0"><strong>CRM Design System</strong></h1>
                <p class="lead"><span class="text-white">Trellis is dedicated to bringing the best possible user experience to its suite of CRM services for the students, staff, alumni, and partners of the University of Arizona. This design system was created as a foundation to ensure that solutions built on Trellis provides a cohesive, accessible, and dynamic user experience that aligns with the university language and tone.</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container my-5 my-md-8">
        <div class="row text-center mb-5">
          <div class="offset-lg-1 col-sm-12 col-md-12 col-lg-10">
            <h2 class="text-center mt-0 text-uppercase text-midnight"><strong>What is a Design System?</strong></h2>
            <p class="lead">The Design System is a single source of truth for designers and developers - a toolkit of guidelines, components and templates on creating interfaces for all Wildcats.</p>
          </div>
        </div>
        <div class="row">
          <div class="card-deck px-3 text-center card-deck-responsive-fix">
            <div class="card col-sm-12 col-md-12 col-lg-12 col-xl-4">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Get Started</h5>
                <p class="card-text">Learn how to begin leveraging Trellis and other University of Arizona frameworks in your efforts.</p>
                <Link className="btn btn-outline-red mt-auto" to="/getstarted">Learn More</Link>
              </div>
            </div>

            <div class="card col-sm-12 col-md-12 col-lg-4">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Documentation</h5>
                <p class="card-text">Explanations and advice on design layouts, common University of Arizona elements and best practices.</p>
                <Link className="btn btn-outline-red mt-auto" to="/documentation">Documentation</Link>
              </div>
            </div>

            <div class="card col-sm-12 col-md-12 col-lg-4">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">UI Library</h5>
                <p class="card-text">Quickly explore interface components, patterns and code within a live sandbox.</p>
                <a className="btn btn-outline-red mt-auto" href={process.env.PUBLIC_URL + '/ui_library/index.html'} rel="noreferrer noopener">UI Library</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="background-wrapper bg-cool-gray">
        <div class="container">
            <h4>Trellis DS Preview</h4>
            <p><strong>11-02-2020</strong> - Trellis Design System is being explored as a potential framework for a future university-wide design system, but is currently only intended for use on Trellis products and feedback at this time. As Trellis Design System is based on Arizona Bootstrap (Bootstrap 4) not yet implemented into Trellis products, some information on this site may not be applicable or functional yet.</p>
        </div>
      </div>
    </>
  );
};
export default Home;