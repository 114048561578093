import React from "react";

const Footer = () => {
  return (
    <footer className="footer mt-auto bg-warm-gray text-center">
        <div className="container py-4">
            <div className="row">
              <div className="col-12">
                <p><small className="text-black"><a href="https://www.arizona.edu/information-security-privacy" target="_blank" rel="noopener noreferrer">University Information Security and Privacy</a></small></p>
                <p><small className="text-black">&copy; {(new Date().getFullYear())} The Arizona Board of Regents on behalf of <a href="https://www.arizona.edu" target="_blank" rel="noopener noreferrer">The University of Arizona</a>.</small></p>
              </div>
            </div>
        </div>
    </footer>
  );
};
export default Footer;






