import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Swatch extends Component {

    constructor(props) {
        super(props)
        this.divRef = React.createRef()

		this.state = {
            swatchClassName: null
		}

    }

    componentWillMount() {
        let swatchClassName = 'swatch ' + this.props.bgColor + ' ' + this.props.textColor;
        this.setState({ swatchClassName: swatchClassName});
    }



    componentDidMount() {
        const styles = window.getComputedStyle(this.divRef.current);
        let swatchHex = this.rgbToHex(styles.backgroundColor);
        this.setState({ swatchHex: swatchHex});
    }


    rgbToHex = (rgb) => {
        var a = rgb.split("(")[1].split(")")[0];
        let ab = a.split(",");

        let r = Number(ab[0]);
        let g = Number(ab[1]);
        let b = Number(ab[2]);
        let valueColor = '#' + [r, g, b].map(x => {
            const hex = x.toString(16)
            return hex.length === 1 ? '0' + hex : hex
          }).join('')
         return valueColor;
    }


    swatchClassName = () => {
        let classString = 'swatch ' + this.props.bgColor + ' ' + this.props.textColor;
        return classString;
    }


	render() {	
		return (
			<React.Fragment>
                <div ref={this.divRef} className={this.state.swatchClassName}>
                    <span class="swatchName">{ this.props.name }</span>
                    <span class="swatchValue">{ this.state.swatchHex }</span>
                </div>
			</React.Fragment>
            
		);
    }
}


Swatch.propTypes = {
    name: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    swatchClassName: PropTypes.string,
    swatchHex: PropTypes.string,
}

export default Swatch;