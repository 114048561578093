import React from "react";
import { Helmet } from "react-helmet";
import { Picture } from 'react-responsive-picture';
import TwoColMenuLayout from '../../../components/Layouts/TwoColMenuLayout';


const Grids = ({ match }) => {
  return (
    <TwoColMenuLayout>

        <Helmet>
            <title>Containers / Grids - CRM Design System | The University of Arizona, Tucson, Arizona</title>
            <meta name="description" content="" />
        </Helmet>

        <h1 class="mt-md-0">Containers / Grids</h1>
        <p class="lead text-muted">Used across many forms of design, grids help establish a clear visual hierarchy and structure against enormous amounts of information through the use of columns, margins and flow.</p>


        <section class="my-7">
            <h2>Working with grid-based layouts</h2>
            <p>Based on the Bootstrap 4 Grid System, <strong>layouts reside within containers on a twelve-column grid system divided by gutters.</strong> Each area is specified by the number of columns that element will span across the grid. The width of those elements is up to you: single (or multiple) elements may span across all twelve columns or columns can be grouped into wider columns.</p>

            <p>If more than twelve columns are placed in a single row, each group of extra columns will, as one unit, wrap onto a new line.</p>
            <Picture className="my-4 img-fluid" srcset={ process.env.PUBLIC_URL + '/images/grid_example@2x.jpg 2x,' + process.env.PUBLIC_URL + '/images/grid_example.jpg 1x' } />
        </section>


        <section class="my-7">
            <h2>Offsetting columns</h2>
            <p>Columns can be offset on the sides: this is a very effective technique for limiting column text areas and centering content. In the example below, the total width of the content is set to ten columns with a one-column left offset, thus centering the content perfectly within the twelve columns.</p>
            <Picture className="my-4 img-fluid" srcset={ process.env.PUBLIC_URL + '/images/grid_example_offset@2x.jpg 2x,' + process.env.PUBLIC_URL + '/images/grid_example_offset.jpg 1x' } />
        </section>


        <section class="my-7">
            <h2>Nested grids</h2>
            <p>Grids can be nested within the column of a parent grid that allows for more complex layouts. Like their parent, child grids also allow up to twelve columns within their own space to place elements.</p>
            <Picture className="my-4 img-fluid" srcset={ process.env.PUBLIC_URL + '/images/grid_example_nesting@2x.jpg 2x,' + process.env.PUBLIC_URL + '/images/grid_example_nesting.jpg 1x' } />
        </section>


        <section class="my-7">
            <h2>Margins, padding and gutters</h2>
            <p>Your elements must be positioned within the columns - <strong>content cannot be aligned to a gutter.</strong> However, there will be scenarios when an element doesn’t fit exactly how you’d like: increasing or decreasing the column span makes the inner content too narrow or wide. In these cases, additional margins or padding can be used on the content’s parent column container to adjust the width appropriately.</p>
            <Picture className="my-4 img-fluid" srcset={ process.env.PUBLIC_URL + '/images/grid_example_margins@2x.jpg 2x,' + process.env.PUBLIC_URL + '/images/grid_example_margins.jpg 1x' } />
        </section>


        <section class="my-7">
            <h2>Responsive layout</h2>
            <p>By default, <strong>Bootstrap grids will automatically adapt and rearrange content at five different viewport sizes.</strong> As an example, a two column horizontal layout on desktop will wrap those columns vertically on a smaller mobile screen.</p>

            <p>You can customize exactly how these layouts behave in each viewport size through the use of responsive CSS classes. Spacing properties such as offsets, margins and padding can also be controlled at each breakpoint. </p>

            <div class="container border-top border-bottom">
                <div class="row">
                    <div class="col-6 col-md-4 col-lg my-3">
                        <p class="mb-0"><strong>X-Small</strong><br />&#60; 576px</p>
                        <p class="my-1"><small>Mobile phones (portrait)</small></p>
                    </div>
                    <div class="col-6 col-md-4 col-lg my-3">
                        <p class="mb-0"><strong>Small</strong><br />576px - 767px</p>
                        <p class="my-1"><small>Mobile phones (landscape)</small></p>
                    </div>
                    <div class="col-6 col-md-4 col-lg my-3">
                        <p class="mb-0"><strong>Medium</strong><br />768px - 991px</p>
                        <p class="my-1"><small>Tablets</small></p>
                    </div>
                    <div class="col-6 col-md-4 col-lg my-3">
                        <p class="mb-0"><strong>Large</strong><br />992px - 1199px</p>
                        <p class="my-1"><small>Netbooks, large tablets</small></p>
                    </div>
                    <div class="col-6 col-md-4 col-lg my-3">
                        <p class="mb-0"><strong>Extra Large</strong><br />&#62; 1200px</p>
                        <p class="my-1"><small>Desktops, laptops</small></p>
                    </div>
                </div>
            </div>

            <Picture className="my-4 img-fluid" srcset={ process.env.PUBLIC_URL + '/images/grid_example_responsive@2x.jpg 2x,' + process.env.PUBLIC_URL + '/images/grid_example_responsive.jpg 1x' } />
        </section>


        <section class="my-7">
            <h2>Full width containers</h2>
            <p>While containers have a maximum width of 1140px, they can also be resized to span the entire width of the viewport. This is most commonly seen on background wrappers and full-width images.</p>
            <Picture className="my-4 img-fluid" srcset={ process.env.PUBLIC_URL + '/images/grid_example_fullwidth@2x.jpg 2x,' + process.env.PUBLIC_URL + '/images/grid_example_fullwidth.jpg 1x' } />
        </section>


        <section class="my-7">
            <h2>Other options</h2>
            <p>There are many other ways to customize the behavior of grids with how they stack, mix and how columns are ordered. Refer to Arizona Bootstrap for more <a href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/layout/grid/" target="_blank" rel="noreferrer noopener" >in-depth information</a> on all the options available to grid layouts.</p>
        </section>


        <section class="my-7">
            <h2>Guidelines</h2>
            <ul>
                <li>When in doubt, <strong>designers should consult closely with developers familiar with the grid system</strong> for feedback and advice on the viability of the layout in page designs.</li>
	            <li><strong>Keep fluid behavior of elements in mind as you create your layout.</strong> You can experiment with how elements behave at different sizes through the UI Library.</li>
	            <li><strong>If you need to break out of the grid, do so mindfully as this may introduce inconsistency and friction to the user.</strong> Consider if the arrangement of a complex layout can be acheived through the effective use of options and properties already available in Bootstrap grids.</li>
            </ul>
        </section>


        <section class="my-7">
            <h2>Additional Information</h2>
            <p><a target="_blank" rel="noopener noreferrer" href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/layout/overview/">Overview / Containers | Arizona Bootstrap</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/layout/grid/">Grids | Arizona Bootstrap</a></p>
        </section>

    </TwoColMenuLayout>
  );


};
export default Grids;