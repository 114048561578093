import React, { Component } from "react";
import SideMenu from '../DS/SideMenu';

class TwoColMenuLayout extends Component {

    render() {
        return (
            <div className="container">
                <div className="row my-md-5">
                <div id="content" className="col-sm-12 col-md-9 order-md-last order-sm-first">
                        {this.props.children}
                    </div>

                    <div className="col-sm-12 col-md-3 mb-4 order-md-first order-sm-last">
                        <SideMenu />
                    </div>
                </div>
            </div>
        );
    }
}
export default TwoColMenuLayout;








