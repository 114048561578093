import React from "react";
import { Helmet } from "react-helmet";
import TwoColMenuLayout from 'components/Layouts/TwoColMenuLayout';


const Buttons = ({ match }) => {
  return (
    <TwoColMenuLayout>

      <Helmet>
        <title>Buttons - CRM Design System | The University of Arizona, Tucson, Arizona</title>
        <meta name="description" content="Buttons are used to direct user intent, communicating important actions such as form choices, submissions and significant links." />
      </Helmet>

      <section className="mb-8">
        <h1 className="mt-md-0">Buttons</h1>
        <p class="lead">Buttons are used to direct user intent, communicating important actions such as form choices, submissions and significant links.</p>
        <div class="card text-center">
          <div class="card-body">
            <div class="container p-0 my-5">
              <div class="row no-gutters">
                <div class="col-md-auto">
                  <img className="img-fluid float-left" src={process.env.PUBLIC_URL + '/images/example_button_1.svg'} alt="Small Button Example" />
                </div>
                <div class="col">
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/example_button_2.svg'} alt="Regular Button Example" />
                </div>
                <div class="col-md-auto">
                  <img className="img-fluid float-right" src={process.env.PUBLIC_URL + '/images/example_button_3.svg'} alt="Large Button Example" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="my-7">
        <h2>Solid Buttons</h2>
        <p><strong>Solid buttons</strong> are used to perform the highest priority actions on the page towards the intended task. <strong>Red Buttons</strong> are commonly used for primary actions, while <strong>Blue Buttons</strong> supplement other (or less important) actions. Ideally solid buttons of a single color should appear only once per page / section, but additional buttons can be used in other areas as alerts / modals, call-to-actions, form options and other places.</p>

        <div className="buttonExamples my-4">
          <div class="container p-0">
            <div class="row align-items-center no-gutters">
              <div class="col offset-md-3 title_cell text-center">
                <div className="btn-wrapper"><p>Default</p></div>
              </div>
              <div class="col title_cell text-center">
                <div className="btn-wrapper"><p>Active</p></div>
              </div>
              <div class="col title_cell text-center">
                <div className="btn-wrapper"><p>Disabled</p></div>
              </div>
            </div>

            <div class="row align-items-center no-gutters">
              <div class="col title_cell">
                <div className="btn-wrapper"><p>Red Button</p></div>
              </div>

              <div class="col">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Default</p></div>
                <div className="btn-wrapper"><button className="btn btn-red btn-block">#8B0015</button></div>
              </div>

              <div class="col">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Active</p></div>
                <div className="btn-wrapper"><button className="btn btn-red btn-block active">#ab0520</button></div>
              </div>

              <div class="col">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Disabled</p></div>
                <div className="btn-wrapper"><button className="btn btn-red btn-block" disabled="true">#8B0015 65%</button></div>
              </div>
            </div>

            <div class="row align-items-center no-gutters">
              <div class="col title_cell">
                <div className="btn-wrapper"><p>Blue Button</p></div>
              </div>

              <div class="col">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Default</p></div>
                <div className="btn-wrapper"><button className="btn btn-blue btn-block">#0C234B</button></div>
              </div>

              <div class="col">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Active</p></div>
                <div className="btn-wrapper"><button className="btn btn-blue btn-block active">#133877</button></div>
              </div>

              <div class="col">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Disabled</p></div>
                <div className="btn-wrapper"><button className="btn btn-blue btn-block" disabled="true">#0C234B 65%</button></div>
              </div>
            </div>
          </div>
        </div>

        <div class="callout callout-midnight">
          <p class="h4 mt-0">Other Button Variations</p>
          <p>While the main button colors cover most use cases, there are other variations of solid button colors available for different actions. More information on these buttons is listed in <a href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/components/buttons/" target="_blank" rel="noopener noreferrer">Buttons - Arizona Bootstrap.</a></p>
        </div>
      </section>


      <section className="my-7">
        <h2>Outline Buttons</h2>
        <p><strong>Outline buttons</strong> can be used to reduce weight on page actions with less emphasis. Outline Buttons (White) are also ideal for use on solid or dark backgrounds.</p>

        <div className="buttonExamples my-4">
          <div class="container p-0">
            <div class="row align-items-center no-gutters">
              <div class="col col-md-3 offset-md-3 title_cell text-center">
                <div className="btn-wrapper"><p>Default</p></div>
              </div>

              <div class="col col-md-3 title_cell text-center">
                <div className="btn-wrapper"><p>Disabled</p></div>
              </div>
            </div>

            <div class="row align-items-center no-gutters">
              <div class="col col-md-3 title_cell">
                <div className="btn-wrapper"><p>Outline Red</p></div>
              </div>

              <div class="col col-md-3">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Default</p></div>
                <div className="btn-wrapper"><button className="btn btn-outline-red btn-block">#8B0015</button></div>
              </div>

              <div class="col col-md-3">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Disabled</p></div>
                <div className="btn-wrapper"><button className="btn btn-outline-red btn-block" disabled="true">#8B0015 65%</button></div>
              </div>
            </div>

            <div class="row align-items-center no-gutters">
              <div class="col col-md-3 title_cell">
                <div className="btn-wrapper"><p>Outline Blue</p></div>
              </div>

              <div class="col col-md-3">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Default</p></div>
                <div className="btn-wrapper"><button className="btn btn-outline-blue btn-block">#0C234B</button></div>
              </div>

              <div class="col col-md-3">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Disabled</p></div>
                <div className="btn-wrapper"><button className="btn btn-outline-blue btn-block" disabled="true">#0C234B 65%</button></div>
              </div>
            </div>

            <div class="row align-items-center no-gutters">
              <div class="col col-md-3 title_cell">
                <div className="btn-wrapper"><p>Outline White</p></div>
              </div>

              <div class="col col-md-3">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Default</p></div>
                <div className="btn-wrapper bg-midnight"><button className="btn btn-outline-white btn-block">#0C234B</button></div>
              </div>

              <div class="col col-md-3">
                <div className="btn-wrapper text-center d-none d-sm-block d-md-none"> <p className="header">Disabled</p></div>
                <div className="btn-wrapper bg-midnight"><button className="btn btn-outline-white btn-block" disabled="true">#0C234B 65%</button></div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="my-7">
        <h5>Solid vs. Outline Buttons?</h5>
        <div class="container">
          <div class="row">
            <div class="card-deck no-gutters">
              <div class="card col-md-4">
                <img className="card-img-top" src={process.env.PUBLIC_URL + '/images/solid_vs_outline_1.svg'} alt="Solid Button Example" />
                <div class="card-body d-flex flex-column">
                  <p class="card-text">Is the button intended for an action like submitting a form or confirming a change? Use a solid button for high-emphasis or priority actions.</p>
                </div>
              </div>

              <div class="card col-md-4">
                <img className="card-img-top" src={process.env.PUBLIC_URL + '/images/solid_vs_outline_2.svg'} alt="Contrasting Button Example" />
                <div class="card-body d-flex flex-column">
                  <p class="card-text">Use outline buttons to compliment primary actions, or reduce visual weight when solid / primary buttons may draw the user’s attention away from the intended task.</p>
                </div>
              </div>

              <div class="card col-md-4">
                <img className="card-img-top" src={process.env.PUBLIC_URL + '/images/solid_vs_outline_3.svg'} alt="Outline Button Example" />
                <div class="card-body d-flex flex-column">
                  <p class="card-text">Outline buttons work more effectively on solid backgrounds where solid buttons would have less contrast and be more difficult to visually interpret.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="my-7">
        <h2>Button Sizes</h2>
        <p>Based on the interface or space tolerances, smaller and larger sizes can be used on all button types.</p>

        <div className="buttonExamples my-4">
          <div class="container p-0">
            <div class="row align-items-center">
              <div class="col col-md-auto">
                <button className="btn btn-blue btn-sm btn-block">Small Button</button>
              </div>

              <div class="col col-md-auto">
                <button className="btn btn-outline-red btn-lg btn-block">Large Button</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-7">
        <h2>Block / Full Width Buttons</h2>
        <p><strong>Block buttons</strong> span the full width of the parent container. These can be visually effective in small containers to fill out the space, or to increase the click / hit area of options on mobile viewports.</p>

        <div className="buttonExamples my-4">
          <div class="container p-0">
            <div class="row align-items-center no-gutters">
              <div class="col col-md-6">
                <button className="btn btn-red btn-block">Block Button</button>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="my-7">
        <h2>Guidelines</h2>
        <ul>
          <li><strong>Label buttons with clear and concise language about what it does or what the user is trying to do.</strong> For example, consider titles like “Next” and “Save” over generic and vague terms like “Click Here” or “OK.”</li>
          <li><strong>Try to keep buttons titled around two / three words.</strong> Use an ampersand for “and” to help save space.</li>
          <li>Unless intentionally used based on the context, <strong>consider the use of regular links over buttons for minor actions</strong> or external links that take users away from the page.</li>
        </ul>
      </section>


      <section class="my-7">
        <h2>Additional Information</h2>
        <p><a target="_blank" rel="noopener noreferrer" href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/components/buttons/">Buttons | Arizona Bootstrap</a></p>
      </section>

    </TwoColMenuLayout>
  );
};

export default Buttons;