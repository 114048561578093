import React from 'react';
import Header from './components/Layouts/Header';
import Footer from './components/Layouts/Footer';

import Home from './pages/Home';
import Vision from './pages/Vision';
import GetStarted from './pages/GetStarted';
import Page404 from './pages/Page404';

import Grids from './pages/Documentation/Layout/Grids';

import Buttons from './pages/Documentation/Components/Buttons';
import Forms from './pages/Documentation/Components/Forms';

import Color from './pages/Documentation/Content/Color';
import Typography from './pages/Documentation/Content/Typography';

//import Headers from './pages/Headers';
//import Cards from './pages/Cards';

import { Route, Switch } from "react-router-dom";
import ScrollToTop from './components/DS/ScrollToTop';
import './App.css';

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />

      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/index.html" component={Home} />

        <Route path="/vision" component={Vision} />

        <Route path="/getstarted" component={GetStarted} />

        <Route path="/documentation" component={Grids} />
        <Route path="/layout/grids" component={Grids} />
        <Route path="/content/color" component={Color} />
        <Route exact={true} path="/content/typography" component={Typography} />
        <Route exact={true} path="/components/buttons" component={Buttons} />
        <Route exact={true} path="/components/forms" component={Forms} />

        <Route path="/:id" component={Page404} />
      </Switch>

      <Footer />
  </>
  );
}

export default App;