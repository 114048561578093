import React from "react";
import { Helmet } from "react-helmet";

const GetStarted = ({ match }) => {
  return (
    <>
      <Helmet>
        <title>Get Started - CRM Design System | The University of Arizona, Tucson, Arizona</title>
        <meta name="description" content="Whether designing a prototype or entering production, start your efforts by following the below steps." />
      </Helmet>

      <div class="container mb-6">
        <div class="row">
          <div class="col-md-8">
              <h1 class="text-midnight"><strong>Get Started</strong></h1>
              <p>Whether designing a prototype or entering production, start your efforts by following the below steps.</p>

              <section class="my-6">
                <h2 class="h3">For Designers</h2>
                <ul>
                  <li>Along with the Design System, <strong>please begin familiarizing yourself with the guidelines of the University of Arizona brand platform</strong> on the <a href="https://brand.arizona.edu/">University of Arizona Brand Resources</a> website.</li>
                  <li><strong>Choose an interface design tool.</strong> Popular interface design choices include <a href="https://www.adobe.com/products/xd.html" target="_blank" rel="noopener noreferrer">Adobe XD</a>, <a href="https://www.sketch.com/" target="_blank" rel="noopener noreferrer">Sketch</a> and <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer">Figma</a>. Coordinate with your team to determine what tool works best within your workflow.</li>
                  <li><strong>Get the fonts.</strong> Proxima Nova is the official web font for university communications and is a part of <a href="https://fonts.adobe.com/search?query=proxima%2Bnova&utf8=%E2%9C%93" target="_blank" rel="noopener noreferrer">Adobe Creative Cloud</a>. Information on font usage and requesting access to Adobe Creative Cloud can be found on <a href="https://brand.arizona.edu/applying-the-brand/typography" target="_blank" rel="noopener noreferrer">Brand Resources.</a></li>
                  <li><strong>Install a Design UI Kit</strong>. Trellis is currently exploring ways to offer a UI Kit for design programs. In the meantime, it is recommended to install <a href="https://www.lightningdesignsystem.com/tools/overview/">Salesforce Lightning Design System tools</a> for the use of Salesforce UI components.</li>
                </ul>
              </section>


              <section class="my-6">
                <h2 class="h3">For Developers</h2>
                <p>University websites are themed with <a href="https://digital.arizona.edu/arizona-bootstrap/" target="_blank" rel="noopener noreferrer">Arizona Bootstrap</a>, a responsive and mobile-first framework containing CSS for grid-based layouts and interface components. <strong>Trellis uses a custom version of Arizona Bootstrap</strong> that extends this framework with specific properties for Salesforce and other web platforms.</p>
                <ul>
                  <li>
                    <strong>Salesforce:</strong> Arizona Bootstrap is automatically applied to pages using the UA Theme 2020 theme in Salesforce Community Builder.
                    <ul>
                      <li>Custom components have access to all Bootstrap properties and should be used for components over Salesforce Lightning Design System (SLDS) classes when possible.</li>
                      <li>Forms generated with Salesforce <a href="https://help.salesforce.com/articleView?id=flow_builder.htm&type=5" target="_blank" rel="noopener noreferrer">Flow Builder</a> will automatically apply custom University of Arizona styling to Flow fields and components.</li>
                      <li>Page layout patterns from the UI Library are included in the Content Layout of the theme. See Salesforce - Change the Content Layout in Experience Builder for more information.</li>
                      <li>Additional CSS styling may be needed for new / unstyled components, or updates made by Salesforce seasonal releases that changes the layout of components.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Other platforms:</strong> Projects requiring Trellis's version of Arizona Bootstrap can include the stylesheet in their HTML with the following CDN:<br />
                    <code>{`<link rel="stylesheet" href="https://design.trellis.arizona.edu/css/arizona-bootstrap-sf.min.css" />`}</code>
                    <div class="mt-2 alert alert-info" role="alert">
                      <p class="mb-0"><strong>Note:</strong> This version of Arizona Bootstrap is <u>only</u> intended for use inside of Salesforce and other Trellis platforms. All other University of Arizona projects are encouraged to use the default <a href="https://digital.arizona.edu/arizona-bootstrap/" target="_blank" rel="noopener noreferrer">Arizona Bootstrap</a>.</p>
                    </div>
                  </li>
                </ul>

              </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetStarted;