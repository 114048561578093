import React from "react";
import { Helmet } from "react-helmet";

const Vision = ({ match }) => {
  return (
    <>
      <Helmet>
        <title>Vision - CRM Design System | The University of Arizona, Tucson, Arizona</title>
        <meta name="description" content="The CRM design system is a foundation to ensure that Trellis solutions provides a cohesive, accessible, and dynamic user experience that aligns with the University of Arizona language and tone." />
      </Helmet>

      <div class="container pb-4 pb-md-6 trellis-vision-bg">
        <div class="row">
          <div class="col-lg-7">
              <h1 class="text-midnight"><strong>Vision</strong></h1>
              <p>Trellis works with a range of technology and services to create digital experiences with applications that facilitate the modern student life and daily workflow of university constituents ranging from students, staff, faculty and external partners. While a multi-platform approach allows for optimization, it risks creating a dissonant user experience by introducing different interaction patterns across sites. Interfaces misaligned with University of Arizona’s brand personality can also compromise confidence and trust with visitors expecting consistency with tools.</p>
              <p>Trellis Design System is intended to address the challenges of adapting diverse solutions to a consistent university brand. The design system is an organized collection of components, patterns and resources that helps teams adopt the spirit of the University of Arizona brand while remaining focused on best practices and logic for differing infrastructures and technology stacks.</p>
          </div>
        </div>
      </div>

      <div class="background-wrapper bg-blue bg-trilines">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h2 class="my-0 my-md-3 text-uppercase"><strong>Trellis solutions aim to be...</strong></h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container my-4 my-md-6">
        <div class="row">
          <div class="card-deck px-3 no-gutters mb-0 mb-md-4 card-deck-responsive-fix">
            <div class="card col-md-4 trellis-seed-bg">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Responsive</h5>
                <p class="card-text">Trellis audiences comprise of anything from computers to mobile phones and tablets. Interfaces should be adaptable to all screen sizes, and carefully consider the behavior and the situation devices are used in.</p>
              </div>
            </div>

            <div class="card col-md-4 trellis-seed-bg">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Accessible</h5>
                <p class="card-text">Products should accommodate all users, including those with disabilities. Semantic HTML markup, screen reader tags and WCAG 2.0 standards should be practiced, along with the university <a href="https://itaccessibility.arizona.edu/content/web-access" target="_blank" rel="noreferrer noopener">IT guidelines for accessibility.</a></p>
              </div>
            </div>

            <div class="card col-md-4 trellis-seed-bg">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Engaging</h5>
                <p class="card-text">Anyone can create a functional product, but personality makes a lasting impression. When appropriate, adding additional touches or finesse to a product makes a world of difference on a digital experience.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card-deck px-3 no-gutters card-deck-responsive-fix">
            <div class="card col-md-4 trellis-seed-bg">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Informative</h5>
                <p class="card-text">Users should always be aware about what is going on within a workflow. Errors should be met with prompt and appropriate feedback, providing a clear solution or a means to  escape the task.</p>
              </div>
            </div>

            <div class="card col-md-4 trellis-seed-bg">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Efficient</h5>
                <p class="card-text">Content shouldn’t overwhelm visitors or compete for their attention. Use clear, concise writing and visuals that guide visitors towards what is relevant, rather than distracting them with too much information.</p>
              </div>
            </div>

            <div class="card col-md-4 trellis-seed-bg">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title text-uppercase">Validated</h5>
                <p class="card-text">What users say and do are often different. Ideas should be prototyped and tested against intended audiences, with decisions informed by research over assumptions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="background-wrapper bg-cool-gray">
        <div class="container">
          <div class="row">
            <div class="offset-lg-2 col-md-12 col-lg-8 text-center">
              <h3 class="h4">From guidelines to frameworks, begin exploring everything you need to jumpstart your efforts within the Design System.</h3>
              <a class="btn btn-red" href="/get-started">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Vision;