import React from "react";
import { Link } from "react-router-dom";

const SideMenu = ({ match }) => {
  return (
    <ul className="nav nav-stacked flex-column">

        <li className="nav-item">
            <Link className="nav-link" to="/layout/grids">Layout</Link>
            <ul className="">
                <li className="nav-item" role="presentation"><Link className="nav-link" to="/layout/grids">Containers / Grids</Link></li>
            </ul>
        </li>

        <li className="nav-item">
        <Link className="nav-link" to="/content/color">Content</Link>
            <ul className="">
                <li className="nav-item" role="presentation"><Link className="nav-link" to="/content/color">Color</Link></li>
                <li className="nav-item" role="presentation"><Link className="nav-link" to="/content/typography">Typography</Link></li>
            </ul>
        </li>

        <li className="nav-item">
        <Link className="nav-link" to="/components/buttons">Components</Link>
            <ul className="">
                <li className="nav-item"><Link className="nav-link" to="/components/buttons">Buttons</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/components/forms">Forms</Link></li>
            </ul>
        </li>
    </ul>
  );
};
export default SideMenu;