import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TypeRow extends Component {
	
	render() {	
		return (
			<React.Fragment>
                <tr>
                    <th scope="row">{this.props.col1}</th>
                    <td>{this.props.col2}</td>
                    <td>{this.props.col3}</td>
                    <td>{this.props.col4}</td>
                    <td>{this.props.col5}</td>
                </tr>
			</React.Fragment>
		);
	}
}

TypeRow.propTypes = {
	col1: PropTypes.string,
    col2: PropTypes.string,
    col3: PropTypes.string,
    col4: PropTypes.string,
    col5: PropTypes.string
}

export default TypeRow;