import React, { Component } from 'react';

class ColorSwatches extends Component {
	
	render() {	
		return (
			<React.Fragment>
                <div class="colorSwatches">
                    { this.props.children }
                </div>
			</React.Fragment>
		);
	}
}

export default ColorSwatches;