import React from "react";
import { Helmet } from "react-helmet";

const Page404 = ({ match }) => {
  return (
    <>

      <Helmet>
        <title>404 Not Found - CRM Design System | The University of Arizona, Tucson, Arizona</title>
      </Helmet>

      <div class="entity entity-paragraphs-item paragraphs-item-uaqs-full-width-bg-wrapper bgHome_l1 background-wrapper bg-blue py-0">
        <div class="inner background-wrapper">
        <div class="container my-md-4">
          <div class="row">
            <div class="col-lg-6">
              <h1 class="text-white mt-0"><strong>404 NOT FOUND</strong></h1>
              <p class="lead"><span class="text-white">Sorry, the page you visited does not exist.</span></p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default Page404;