import React from "react";
import Menu from './Menu';

const Header = ({ match }) => {
  return (
    <>
        { /* Arizona Red Header */ }
        <header class="bg-red arizona-header" id="header_arizona">
            <div class="container">
                <div class="row">
                    <a class="arizona-logo" href="http://www.arizona.edu" title="The University of Arizona homepage"><img class="arizona-line-logo" alt="The University of Arizona Wordmark Line Logo White" src="https://cdn.digital.arizona.edu/logos/v1.0.0/ua_wordmark_line_logo_white_rgb.min.svg" /></a>
                </div>
            </div>
            <div class="redbar-buttons d-lg-none">
                {/* <button data-toggle="offcanvas" type="button" data-target="#navbarOffcanvasDemo" aria-controls="navbarOffcanvasDemo" class="btn btn-redbar"><span class="icon material-icons-sharp"> search </span><span class="icon-text"> search </span></button> */}
                <button data-toggle="offcanvas" type="button" data-target="#navbarOffcanvasDemo" aria-controls="navbarOffcanvasDemo" class="btn btn-redbar"><span class="icon material-icons-sharp"> menu </span><span class="icon-text"> menu </span></button>
            </div>
        </header>

        { /* Site ident */ }
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <a href="/" title="Hub | Home" class="qs-site-logo d-block" rel="home">
                        <img src={process.env.PUBLIC_URL + '/images/Trellis_WEB-HEADER.png'} alt="Trellis CRM Design System | Home" className="img-fluid" />
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-lg-8">
                    <div class="row"></div>
                </div>
            </div>
        </div>

        { /* Top Navigation / Mobile Menu */ }
        <div className="container">
            <Menu />
        </div>
    </>
  );
};
export default Header;








