import React from "react";
import { Helmet } from "react-helmet";
import TwoColMenuLayout from 'components/Layouts/TwoColMenuLayout';
import ColorSwatches from 'components/DS/ColorSwatches';
import Swatch from 'components/DS/Swatch';

const Color = ({ match }) => {
  return (
    <TwoColMenuLayout>

        <Helmet>
            <title>Colors - CRM Design System | The University of Arizona, Tucson, Arizona</title>
            <meta name="description" content="Colors comprise of a collection of primary, secondary and accent choices to create visual impact and consistancy - establishing harmony with the University of Arizona ecosystem." />
        </Helmet>

        <h1 class="mt-md-0">Color</h1>
        <p class="lead text-muted">Colors comprise of a collection of primary, secondary and accent choices to create visual impact and consistancy - establishing harmony with the University of Arizona ecosystem.</p>


        <section class="my-7">
            <h2>Primary Colors</h2>
            <p><strong>Primary colors</strong> are the main colors used for backgrounds, display copy, and main navigation elements.</p>

            <ColorSwatches>
                <Swatch name="Arizona Red" bgColor="bg-red" textColor="text-white" />
                <Swatch name="Arizona Blue" bgColor="bg-blue" textColor="text-white" />
            </ColorSwatches>
        </section>


        <section class="my-7">
            <h2>Highlight Colors</h2>
            <p><strong>Highlight colors</strong> are intended to be used when the primary colors won't work due to contrast issues with accessibility standards. These can also be used as background colors.</p>

            <ColorSwatches>
                <Swatch name="Chili" bgColor="bg-chili" textColor="text-white" />
                <Swatch name="Bloom" bgColor="bg-bloom" textColor="text-ash" />
            </ColorSwatches>

            <ColorSwatches>
                <Swatch name="Midnight" bgColor="bg-midnight" textColor="text-white" />
                <Swatch name="Azurite" bgColor="bg-azurite" textColor="text-white" />
                <Swatch name="Oasis" bgColor="bg-oasis" textColor="text-ash" />
                <Swatch name="Sky" bgColor="bg-sky" textColor="text-ash" />
            </ColorSwatches>
        </section>


        <section class="my-7">
            <h2>Neutral Colors</h2>
            <p><strong>Neutral colors</strong> are used for secondary backgrounds and divider lines.</p>

            <ColorSwatches>
                <Swatch name="Cool Gray" bgColor="bg-cool-gray" textColor="text-dark-silver" />
                <Swatch name="Warm Gray" bgColor="bg-warm-gray" textColor="text-dark-silver" />
            </ColorSwatches>
        </section>


        <section class="my-7">
            <h2>Secondary Colors</h2>
            <p>Used for emphasis or to highlight specific pieces. Do not use in large areas, background colors, or action items.</p>

            <ColorSwatches>
                <Swatch name="Leaf" bgColor="bg-leaf" textColor="text-sage" />
                <Swatch name="River" bgColor="bg-river" textColor="text-white" />
                <Swatch name="Silver" bgColor="bg-silver" textColor="text-ash" />
                <Swatch name="Mesa" bgColor="bg-mesa" textColor="text-white" />
            </ColorSwatches>
        </section>


        <section class="my-7">
            <h2>Guidelines</h2>
            <ul>
                <li><strong>Test color combinations against each other with an online contrast checker or design/browser plugin to ensure that it passes the WCAG AA requirement for accessibility.</strong> A contrast ratio of 4:5:1 (or 3:1 for large text) is required to meet the minimium W3C AA rating.</li>
            </ul>
        </section>


        <section class="my-7">
            <h2>Additional Information</h2>
            <p><a target="_blank" rel="noopener noreferrer" href="https://brand.arizona.edu/applying-the-brand/colors">Colors | University of Arizona Brand Resources</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/getting-started/color-contrast/">Color Contrast | Arizona Bootstrap</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://itaccessibility.arizona.edu/content/color-contrast">Color Contrast - Choose Color With Care | IT Accessibility</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-without-color.html">Understanding WCAG 2.0 - Use of Color</a></p>
        </section>

    </TwoColMenuLayout>
  );


};
export default Color;