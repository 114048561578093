import React from "react";
import { Helmet } from "react-helmet";
import { Picture } from 'react-responsive-picture';
import TwoColMenuLayout from 'components/Layouts/TwoColMenuLayout';


const Buttons = ({ match }) => {
  return (
    <TwoColMenuLayout>

        <Helmet>
            <title>Forms - CRM Design System | The University of Arizona, Tucson, Arizona</title>
            <meta name="description" content="Forms comprise of a group of text, input and choice components that allows users to fill in information and submit data." />
        </Helmet>

        <section className="mb-8">
            <h1 className="mt-md-0">Forms</h1>
            <p class="lead">Forms comprise of a group of text, input and choice components that allows users to fill in information and submit data.</p>
            <div class="card text-center">
                <div class="card-body">
                    <div class="container p-0 my-5">
                    <div class="row no-gutters">
                        <div class="col-md-auto">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/example_form.svg'} alt="Form Example" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="my-7">
            <h2>Text Fields</h2>
            <p><strong>Text fields</strong> are input elements for single line entries such as text, email addresses or keywords. Text fields can have a top (or left) aligned label along with optional help text or a placeholder in the field to provide additional context.</p>
            <div class="form-group">
                <label for="exampleTextField">Label <span class="form-required" title="This field is required."> *</span></label>
                <input type="email" class="form-control" id="exampleTextField" aria-describedby="exampleTextFieldHelp" placeholder="Placeholder Text" />
                <small id="exampleTextFieldHelp" class="form-text text-muted">A block of help text that breaks onto a new line and may extend beyond one line.</small>
            </div>
        </section>


        <section className="my-7">
            <h5>Top-aligned or left-aligned labels?</h5>
            <div class="container">
                <div class="row">
                    <div class="card-deck no-gutters">
                        <div class="card col-md-4">
                            <Picture srcSet={process.env.PUBLIC_URL + '/images/example_textField_1@2x.png 2x,' + process.env.PUBLIC_URL + '/images/example_textField_1.png 1x'} />
                            <div class="card-body d-flex flex-column">
                            <p class="card-text">Top-aligned labels are usually recommended for quicker scanning, accessibility and optimal formatting for mobile viewports.</p>
                            </div>
                        </div>

                        <div class="card col-md-4">
                            <Picture srcSet={process.env.PUBLIC_URL + '/images/example_textField_2@2x.png 2x,' + process.env.PUBLIC_URL + '/images/example_textField_2.png 1x'} />
                            <div class="card-body d-flex flex-column">
                            <p class="card-text">Left-aligned labels may make more sense in areas where space is limited, or for very simple or single form controls.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="my-7">
            <h2>Text Areas</h2>
            <p><strong>Text areas</strong> are a multiline component that allows users to write out longer bodies of text.</p>
            <div class="form-group">
                <label for="exampleTextArea">Text Area Label</label>
                <textarea class="form-control" id="exampleTextArea" rows="3"></textarea>
            </div>
        </section>


        <section className="my-7">
            <h2>Select Menu</h2>
            <p><strong>Select menus</strong> allow users to choose a single option from a list of items.</p>
            <div class="form-group">
                <label for="exampleSelectField">Choose an Additional Side</label>
                <select class="form-control" id="exampleSelectField">
                    <option>Fries</option>
                    <option>Mashed Potatoes</option>
                    <option>Corn</option>
                    <option>Fruit</option>
                    <option>Pickles</option>
                </select>
            </div>
        </section>


        <section className="my-7">
            <h2>Checkboxes</h2>
            <p><strong>Checkboxes</strong> enable the selection of  multiple options from a list. They can also used for toggling a single option where the meaning is obvious (such as "yes / no”).</p>

            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                <label class="form-check-label" for="defaultCheck1">Red Sedan</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                <label class="form-check-label" for="defaultCheck2">Green Truck</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck3" />
                <label class="form-check-label" for="defaultCheck3">Blue Motorcycle</label>
            </div>

        </section>


        <section className="my-7">
            <h2>Radio Buttons</h2>
            <p><strong>Radios buttons</strong> are best used for a list of two or more mutually exclusive options that require a default choice, or options needing comparison to understand its context.</p>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                <label class="form-check-label" for="exampleRadios1">
                    Overnight Express
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                <label class="form-check-label" for="exampleRadios2">
                    Two-Day Shipping
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" />
                <label class="form-check-label" for="exampleRadios3">
                    In-Store Pickup
                </label>
            </div>
        </section>


        <section className="my-7">
            <h2>Switches</h2>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" />
                <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
            </div>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" disabled id="customSwitch2" />
                <label class="custom-control-label" for="customSwitch2">Disabled switch element</label>
            </div>
        </section>



        <section className="my-7">
            <h2>Guidelines</h2>
            <ul>
                <li><strong>Do not use placeholders as replacements for labels.</strong> The entire context of the field is immediately lost when the user enters text into the field. Accessibility is also affected as screen readers and other assistive technologies do not consider placeholders as labels.</li>
                <li><strong>When the amount of text expected to be entered in a field exceeds a single line or a brief sentence, it is suggested to use a text area instead.</strong></li>
                <li><strong>Aligning fields side-by-side (inline alignment) can make it more difficult for the user to quickly scan and navigate the form.</strong> Exceptions to this rule are fields usually expected to be aligned together, such as First / Last Name or City / State / Zip.</li>
                <li>
                    <strong>Options lists with either too-few or too-many choices can introduce friction to navigation (especially on mobile devices).</strong> Consider the choices of select lists, checkboxes or radio buttons carefully based on the content.
                    <ul>
                        <li><strong>Select menus</strong> are ideal for a small handful of choices, but can become more difficult to use as the amount of options grows. For large amounts of choices (generally 15-20+), consider supplementing a select menu with an auto-completion / search field to allow the user to narrow down their options.</li>
                        <li><strong>Checkboxes</strong> or <strong>switches</strong> make sense for multiple selections or toggling a self-explanatory action (enable/disable). However, large amounts of these options can become difficult to look through, and the meaning of toggling a checkbox or switch may not always be clear.</li>
                        <li>Use <strong>radio buttons</strong> instead of checkboxes for single selections, or when more context is required between comparing choices. </li>
                    </ul>
                </li>
                <li><strong>When forms are getting too long, consider grouping fields together into sections or divided across steps.</strong> If laying out forms across pages, be mindful to group the fields on each page carefully in relationship to each other - not requiring the user to return to previous pages to compare fields with later ones.</li>
            </ul>
        </section>


        <section class="my-7">
            <h2>Additional Information</h2>
            <p><a target="_blank" rel="noopener noreferrer" href="https://digital.arizona.edu/arizona-bootstrap/docs/2.0/components/forms/">Forms | Arizona Bootstrap</a></p>
        </section>

    </TwoColMenuLayout>
  );
};

export default Buttons;